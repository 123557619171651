<template>
    <div class="home-carousel">
        <b-carousel
            class="full-carousel"
            :pause-hover="false"
            animated="fade"
            :indicator="false"
            :arrow="false"
            :interval="20000"
        >
            <b-carousel-item
                class="carousel-slide"
                v-for="(image, i) in this.carousels"
                :key="i"
            >
                <!-- <b-image :lazy="false" class="bg-image" :src="getImgUrl(i)"></b-image> -->
                <figure :lazy="false" class="bg-image">
                    <nuxt-img
                        alt="carousel-image"
                        loading="lazy"
                        :src="
                            `https://res.cloudinary.com/dwiebcqwl/image/upload/c_scale,f_auto,w_250/` +
                            image.public_id +
                            `.webp`
                        "
                    />
                </figure>
            </b-carousel-item>
        </b-carousel>
    </div>
</template>
<style lang="scss" scoped>
.absolute-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.bg-image {
    opacity: 1;
    // mix-blend-mode:exclusion;
    // min-height: 100%;
    height: 90vh;
    width: 100vw;
    overflow: hidden;
    > img {
        height: 100%;
        width: 100%;
        object-position: center !important;
        object-fit: cover !important;
    }
    transform: scale(1.01);

    filter: brightness(0.65) blur(0.5rem);
    mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
    );
}

.carousel-text {
    font-size: 1.2rem;
    text-align: justify;
    color: var(--color); // mix-blend-mode: darken;
    @media (min-width: 960px) {
        font-size: 2rem;
        max-width: 940px;
        margin: auto;
    }
}

.carousel-hero {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.home-carousel {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
</style>
<script>
// const GET_HOME_PARAGRAPH = `
//  query getContent($id: HomecontentWhereUniqueInput!) {
//     Homecontent(where: $id) {
//       id
//       homeParagraphIt
//       homeParagraphEn
//     }
//   }
// `;

function graphql(query, variables = {}) {
    return fetch(
        process.env.NODE_ENV === "production"
            ? "https://www.tempiodelfuturo.art/admin/api"
            : "http://localhost:3000/admin/api",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                variables,
                query,
            }),
        }
    ).then(function (result) {
        return result.json();
    });
}
export default {
    name: "HomeCarousel",
    data() {
        return {
            carousels: this.cldImages,
        };
    },
    mounted() {
        this.asyncData();
    },
    methods: {
        async asyncData() {
            // this.isLoadingData = true;

            try {
                let cldImages = await fetch(
                    "https://res.cloudinary.com/dwiebcqwl/image/list/home-carousel.json"
                ).then((res) => res.json());

                this.carousels = cldImages.resources;
            } catch (e) {
                console.error(e);
            }
        },
    },
    props: {
        cldImages: {
            type: Array,
            default: () => [],
        },
        // title: {
        //   type: String,
        //   required: true,
        // },
        // image: {
        //   type: String,
        //   required: true,
        // },
        // content: {
        //   type: String,
        //   required: true,
        // },
    },
};
</script>
