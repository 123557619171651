<template>
    <div>
        <HomeCarousel />
        <section class="main-content">
            <!-- <div class="bottom-bd"></div> -->
            <GridBox
                class="my-3"
                v-if="homeConfig.homeParagraphIt || homeConfig.homeParagraphEn"
            >
                <GridItem size="100%" :colidx="3" class="p-0" transparent>
                    <h3
                        class="is-main-claim is-text is-uppercase has-text-weight-bold"
                    >
                        {{
                            $i18n.locale === "en"
                                ? homeConfig.homeParagraphEn
                                : homeConfig.homeParagraphIt
                        }}
                    </h3>
                </GridItem>
            </GridBox>
            <GridBox class="left-line-alt mb-6" v-if="homeConfig.announcements">
                <GridItem size="100%" :colidx="3" transparent class="m-0 p-0">
                    <h3
                        class="is-claim is-size-1 m-0 p-0 is-primary is-uppercase has-text-weight-bold"
                    >
                        {{ $t("home.announcements") }}
                    </h3>
                </GridItem>

                <NewsCard
                    v-for="announcement in homeNews"
                    :key="announcement.id"
                    :loaded="$fetchState.pending ? false : true"
                    :record="announcement"
                    :col="computeCol('sm')"
                    :clickevt="openNews"
                    :seatsOptions="seatsOptions"
                    :onReady="onReady"
                />
            </GridBox>
            <GridBox
                class="left-line mb-6"
                v-if="signupWarning && !loggedUser.id"
            >
                <GridItem size="100%" :colidx="3" class="p-0" transparent>
                    <h3
                        class="is-claim is-size-1 is-primary is-uppercase has-text-weight-bold"
                    >
                        {{ $t("home.signupHeader") }}
                        <b-button
                            class="is-uppercase is-size-4 primary-btn"
                            icon-left="far fa-times-circle"
                            @click="signupWarning = false"
                        >
                        </b-button>
                    </h3>
                    <p class="is-size-5 is-subtle has-text-weight-bold">
                        {{ $t("home.membershipRequirement") }}
                    </p>
                </GridItem>

                <GridItem
                    size="100%"
                    :colidx="computeCol('sm')"
                    class="between"
                    glassy
                >
                    <p class="is-size-5 is-subtle mb-4 has-text-weight-bold">
                        {{ $t("home.signupClaim") }}
                    </p>
                    <nuxt-link :to="localePath('/u/register')">
                        <b-button
                            class="is-uppercase is-size-4 primary-btn self-end"
                        >
                            {{ $t("home.signup") }}
                        </b-button></nuxt-link
                    >
                </GridItem>
                <GridItem
                    size="100%"
                    :colidx="computeCol('sm')"
                    class="between"
                    glassy
                >
                    <p class="is-size-5 is-subtle has-text-weight-bold">
                        {{ $t("home.loginClaim") }}
                    </p>
                    <nuxt-link :to="localePath('/u/login')">
                        <b-button
                            class="is-uppercase is-size-4 primary-btn self-end"
                        >
                            {{ $t("home.login") }}
                        </b-button></nuxt-link
                    >
                </GridItem>
            </GridBox>

            <!--<GridBox class="left-line-alt mb-6" v-if="homeConfig.featured">
                <GridItem size="100%" :colidx="3" transparent class="m-0 p-0">
                    <h3
                        class="is-claim is-size-1 m-0 p-0 is-primary-alt is-uppercase has-text-weight-bold"
                    >
                        {{ $t("home.featured") }}
                    </h3>
                </GridItem>

                <EventCard
                    hasDay
                    v-for="event in nextUpNight"
                    :key="event.id"
                    :loaded="$fetchState.pending ? false : true"
                    :event="event"
                    :col="event.category?.value === 'events' ? 3 : 2"
                    :clickevt="openEvt"
                    :seatsOptions="seatsOptions"
                    :onReady="onReady"
                />
            </GridBox>
            <GridBox class="left-line-alt">
                <GridItem size="100%" :colidx="3" transparent class="m-0 p-0">
                    <h3
                        class="is-claim is-size-1 m-0 p-0 is-primary-alt is-uppercase has-text-weight-bold"
                    >
                        {{ $t("home.nextup") }}
                    </h3>
                </GridItem>
                <GridItem
                    size="100%"
                    :colidx="3"
                    transparent
                    class="m-0 p-0"
                    v-if="!nextUpNight.length && !nextUpCourses.length"
                >
                    <p class="is-size-5 is-subtle has-text-weight-bold">
                        {{ $t("home.noEvents") }}
                    </p>
                </GridItem>
                <GridItem size="100%" :colidx="3" transparent class="m-0 p-0">
                    <h3
                        class="is-claim is-size-5 m-0 p-0 is-subtle is-uppercase has-text-weight-bold"
                    >
                        {{
                            $i18n.locale === "en"
                                ? nextUpNight[0]?.category?.nameEn
                                : nextUpNight[0]?.category?.name
                        }}
                    </h3></GridItem
                >
                <EventCard
                    hasDay
                    :hasCategory="false"
                    v-for="event in nextUpNight"
                    :key="event.id"
                    :loaded="$fetchState.pending ? false : true"
                    :event="event"
                    :col="event.category?.value === 'events' ? 3 : 2"
                    :clickevt="openEvt"
                    :seatsOptions="seatsOptions"
                    :onReady="onReady" />
                <GridItem size="100%" :colidx="3" transparent class="m-0 p-0">
                    <h3
                        class="is-claim is-size-5 m-0 mt-3 p-0 is-subtle is-uppercase has-text-weight-bold"
                    >
                        {{
                            $i18n.locale === "en"
                                ? nextUpCourses[0]?.category?.nameEn
                                : nextUpCourses[0]?.category?.name
                        }}
                    </h3></GridItem
                >
                <EventCard
                    hasDay
                    :hasCategory="false"
                    v-for="event in nextUpCourses"
                    :key="event.id"
                    :loaded="$fetchState.pending ? false : true"
                    :event="event"
                    :col="event.category?.value === 'events' ? 2 : 1"
                    :clickevt="openEvt"
                    :seatsOptions="seatsOptions"
                    :onReady="onReady"
            /></GridBox> -->
        </section>
    </div>
</template>
  
<style lang="scss" scoped>
.self-end {
    width: 100%;
    align-self: flex-end;
}
.between {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.left-line {
    border-left: 1px solid var(--color-primary);
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 0;

    /* margin-bottom: 2rem; */
}
.left-line-alt {
    border-left: 1px solid var(--color-primary-alt);
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 0;

    /* margin-bottom: 2rem; */
}
.left-line-text {
    border-left: 1px solid var(--color-subtle);
    padding-left: 1rem;
    padding-bottom: 0;
    padding-top: 0;

    /* margin-bottom: 2rem; */
}
.is-claim {
    line-height: 1.1;
    // break with hyphens
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.is-main-claim {
    line-height: 1.5 !important;

    // break with hyphens
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 2rem !important;
    height: 75vh;
    margin-bottom: 0;
    @media (min-width: 960px) {
        height: 65vh;

        font-size: 2.5rem !important;
    }
}
.transparent-card {
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
}
.main-content {
    z-index: 1;
    padding-bottom: 5rem;
    max-width: 1280px !important;
    /* margin-bottom: 2rem; */
    padding: 1rem;
    min-height: calc(100vh - 5rem);
    @media (min-width: 960px) {
        padding-top: 5rem !important;
    }
}
</style>
  <script>
// import VideoPlayer from "~/components/videoPlayer";
import HomeCarousel from "~/components/HomeCarousel";
import GridBox from "~/components/GridBox";
import GridItem from "~/components/GridItem";
import EventCard from "~/components/EventCard";
import NewsCard from "~/components/NewsCard";

const GET_EVENTS = `
  query getEvents($first: Int, $skip: Int, $start_gte: DateTime, $end_lte: DateTime, $category: TipoditesseramentoWhereInput) {
    allEventi(
            sortBy: start_ASC,
            first: $first,
            skip: $skip,
            where: {
                start_gte: $start_gte,
                start_lte: $end_lte,
                category: $category
            }
        )  {
      id
      title
      start
      end
      summary
      backgroundColor
      link
      urlSlug
      allDay
      recurring
      daysOfWeek
      startRecur
      paid
      seats
      seatsTaken
      endRecur
      display
      image
      featured
      category{
        name
        nameEn
        value
        id
      }
    }
  }
`;

const GET_NEWS = `
  query getNews($first: Int, $skip: Int) {
    allAnnunci(
            sortBy: newsDate_ASC,
            first: $first,
            skip: $skip,
            where: {
                public: true,
            }
        )  {
        id
        backgroundColor
        title
        summary
        newsDate
        image
        link
        membersOnly
        public
        featured
    }
  }
`;

const CHECK_USER = `
    query check {
        authenticatedPublicUtente {
            id
            email
            firstName
            lastName
            active
            memberships {
                valid
                id
                nationalId
                fiscalCode
                dob
                address
                createdAt
                acceptsMarketing
                expires
                citizenship
                city
                country
                type {
                    id
				    name
                    nameEn
                    value
			    }
            }
        }
    }
`;
const GET_HOME_CONFIG = `
 query getContent($id: HomecontentWhereUniqueInput!) {
    Homecontent(where: $id) {
      id
      homeParagraphIt
      homeParagraphEn
      featured
      announcements
    }
  }
`;
function graphql(query, variables = {}) {
    return fetch(
        process.env.NODE_ENV === "production"
            ? "https://www.tempiodelfuturo.art/admin/api"
            : "http://localhost:3000/admin/api",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                variables,
                query,
            }),
        }
    ).then(function (result) {
        return result.json();
    });
}
export default {
    name: "Home",
    data() {
        return {
            pageTitle: "TDF*P",
            signupWarning: true,
            nextUpNight: [],
            nextUpCourses: [],
            homeNews: [],
            isLoadingData: false,
            seatsOptions: [],
            loggedUser: {},
            homeConfig: {},
            seatsOptions: {
                decimalPlaces: 0,
                duration: 0.5,
                useEasing: true,
                useGrouping: true,
                // separator: ",",
                // decimal: ".",
                // prefix: `${this.currency} `,
                suffix: "",
            },
        };
    },
    components: {
        // VideoPlayer,
        HomeCarousel,
        GridBox,
        GridItem,
        EventCard,
        NewsCard,
        // SideChat,
    },
    layout: "default",

    mounted() {
        window.addEventListener("resize", () => {
            this.$forceUpdate();
        });
        this.$store.commit("setCurrPageTitle", this.$t("home.title"));
        this.$fetch();
        this.enterAnimation();
    },
    watch: {
        $route(to, from) {
            this.$store.commit("setCurrPageTitle", this.$t("home.title"));
        },
    },
    head() {
        return {
            title: this.pageTitle,
        };
    },
    async fetch() {
        this.isLoadingData = true;
        try {
            let { data: homeData } = await graphql(GET_HOME_CONFIG, {
                // id: { id: this.eventProps.id },
                id: { id: "6249ab002e78734f28e1219f" },
            });
            this.homeConfig = homeData.Homecontent;

            let { data: nextupData } = await graphql(GET_EVENTS, {
                start_gte: new Date().toISOString(),
                first: 5,
                skip: 0,
            });
            let { data: newsData } = await graphql(GET_NEWS, {
                newsDate: new Date().toISOString(),
                first: 5,
                skip: 0,
            });
            const hexToRgbCard = (hex) => {
                const hexToRgb = (h) => {
                    let r = 0,
                        g = 0,
                        b = 0;

                    // 3 digits
                    if (h.length === 4) {
                        r = "0x" + h[1] + h[1];
                        g = "0x" + h[2] + h[2];
                        b = "0x" + h[3] + h[3];
                        // 6 digits
                    } else if (h.length === 7) {
                        r = "0x" + h[1] + h[2];
                        g = "0x" + h[3] + h[4];
                        b = "0x" + h[5] + h[6];
                    }
                    return `rgba(${+r}, ${+g}, ${+b}, 0.6)`;
                };
                return hexToRgb(hex);
            };
            if (this.$store.getters["public-auth/authedUser"].id) {
                const { data } = await graphql(CHECK_USER);

                if (data && data.authenticatedPublicUtente) {
                    if (data.authenticatedPublicUtente.active) {
                        this.loggedUser = data.authenticatedPublicUtente;
                        this.$store.commit(
                            "public-auth/SET_USER",
                            {
                                id: data.authenticatedPublicUtente.id,
                                email: data.authenticatedPublicUtente.email,
                                firstName:
                                    data.authenticatedPublicUtente.firstName,
                                lastName:
                                    data.authenticatedPublicUtente.lastName,
                            }
                            // { root: true }
                        );
                    } else {
                        this.$store.dispatch("setLoggedIn", false);
                    }
                } else {
                    this.$store.dispatch("setLoggedIn", false);
                }
            }
            if (newsData && newsData.allAnnunci) {
                newsData.allAnnunci.forEach((news) => {
                    if (news.image) {
                        const imageId = news.image
                            .split("/")
                            .pop()
                            .split(".")[0];
                        const cloudinaryId = "dwiebcqwl";
                        const actualImage = `https://res.cloudinary.com/${cloudinaryId}/image/upload/c_scale,f_auto,w_200/announcements/${imageId}.webp`;

                        news.image = actualImage;
                    } else {
                        news.image = null;
                    }
                    news.rgba = hexToRgbCard(news.backgroundColor);
                    news.selectedDay = new Date(
                        news.newsDate
                    ).toLocaleDateString(this.$i18n.locale, {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                    });
                });
                this.homeNews = newsData.allAnnunci;
            }
            if (nextupData && nextupData.allEventi) {
                nextupData.allEventi.forEach((event) => {
                    if (event.image) {
                        const imageId = event.image
                            .split("/")
                            .pop()
                            .split(".")[0];
                        const cloudinaryId = "dwiebcqwl";
                        const actualImage = `https://res.cloudinary.com/${cloudinaryId}/image/upload/c_scale,f_auto,w_200/events/${imageId}.webp`;

                        event.image = actualImage;
                    } else {
                        event.image = null;
                    }
                    event.rgba = hexToRgbCard(event.backgroundColor);
                    event.startString = `H${new Date(
                        event.start
                    ).toLocaleTimeString(this.$i18n.locale, {
                        hour: "2-digit",
                        minute: "2-digit",
                    })}`;
                    event.endString = new Date(event.end).toLocaleTimeString(
                        this.$i18n.locale,
                        {
                            hour: "2-digit",
                            minute: "2-digit",
                        }
                    );
                    event.selectedDay = new Date(
                        event.start
                    ).toLocaleDateString(this.$i18n.locale, {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                    });
                    event.selectedEndDay = new Date(
                        event.end
                    ).toLocaleDateString(this.$i18n.locale, {
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                    });
                    if (event.category.value === "events") {
                        event.featured = true;
                    } else {
                        event.featured = false;
                    }
                });
                this.nextUpNight = nextupData.allEventi.filter(
                    (event) => event.category.value === "events"
                );
                this.nextUpCourses = nextupData.allEventi.filter(
                    (event) => event.category.value === "courses"
                );
            }
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        onReady() {},
        computeCol(size) {
            // event.featured ? 2 : 1
            // console.log(evt);
            // check if window is present
            if (process.client && window) {
                const width = window.innerWidth;
                if (size === "lg") {
                    if (width > 1560) {
                        return 3;
                    } else if (width > 1280) {
                        return 3;
                    } else if (width > 960) {
                        return 3;
                    } else if (width > 560) {
                        return 2;
                    } else {
                        return 1;
                    }
                } else {
                    if (width > 1560) {
                        return 1;
                    } else if (width > 1280) {
                        return 1;
                    } else if (width > 960) {
                        return 1;
                    } else if (width > 560) {
                        return 1;
                    } else {
                        return 1;
                    }
                }
            } else {
                return 1;
            }
        },
        openEvt(event) {
            this.$router.push({
                path: this.localePath(`/event/${event.id}`),
            });
        },
        openNews(news) {
            this.$router.push({
                path: this.localePath(`/news/${news.id}`),
            });
        },
        enterAnimation() {
            const anime = this.$anime;
            anime({
                targets: ".main-content",
                easing: "easeInOutQuad",
                opacity: [0, 1],
                filter: ["blur(2rem)", "blur(0px)"],
                duration: 360,
            });
        },
    },
};
</script>
