var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridItem',{staticClass:"small",attrs:{"colidx":_vm.col,"height":_vm.height}},[_c('div',{staticClass:"clickable-area",on:{"click":function($event){return _vm.clickevt(_vm.record)}}}),_vm._v(" "),(_vm.loaded)?_c('div',{staticClass:"gradient-overlay",style:(_vm.elevated && _vm.record.public === false
                ? ''
                : 'background: linear-gradient(-35deg, ' +
                  _vm.record.rgba +
                  ' 0%, rgba(25, 22, 32,0) 100%);')}):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',{staticClass:"calendar-container"},[(_vm.record.image)?_c('figure',{staticClass:"hero-img"},[_c('nuxt-img',{attrs:{"loading":"lazy","src":_vm.record.image,"alt":_vm.record.title}})],1):_vm._e(),_vm._v(" "),_c('GridBody',{staticClass:"card-body"},[_c('div',{staticClass:"card-block"},[(_vm.elevated)?_c('div',{staticClass:"fixed-top-left"},[(_vm.record.public && _vm.elevated)?_c('b-icon',{staticClass:"has-text-success",attrs:{"icon-pack":"fas","icon":"fas fa-globe"}}):_vm._e(),_vm._v(" "),(!_vm.record.public && _vm.elevated)?_c('b-icon',{staticClass:"has-text-danger",attrs:{"icon-pack":"fas","icon":"fas fa-lock"}}):_vm._e(),_vm._v("\n                    "+_vm._s(_vm.record.featured ? "🌟" : "")+"\n                    "+_vm._s(_vm.record.membersOnly ? "🎫" : "")+"\n                ")],1):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"name has-text-weight-bold mt-1 is-size-4 is-uppercase",style:('color:' + _vm.record.backgroundColor + ';')},[_vm._v("\n                    "+_vm._s(_vm.record.title)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"flex-between"}),_vm._v(" "),_c('div',{staticClass:"flex-between"},[_c('span',{staticClass:"is-size-6 m-0 p-0 is-uppercase has-text-weight-bold is-subtle"},[_vm._v("\n                        "+_vm._s(_vm.$t("news.publishedOn"))+"\n                        "+_vm._s(new Date(_vm.record.newsDate).toLocaleTimeString(
                                _vm.$i18n.locale,
                                {
                                    day: "numeric",
                                    month: "short",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                    // timeZone: "UTC",
                                    // timeZoneName: "short",
                                    formatMatcher: "best fit",
                                    hourCycle: "h24",
                                }
                            ))+"\n                    ")])])]),_vm._v(" "),_c('div',{staticClass:"card-block"},[(_vm.record.summary)?_c('p',{staticClass:"is-size-6 mt-2 summary-box",domProps:{"innerHTML":_vm._s(_vm.record.summary)}}):_vm._e()])])],1):_c('div',{staticClass:"calendar-container"},[_c('figure',{staticClass:"hero-img"},[_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"100%","animated":"","count":1}})],1),_vm._v(" "),_c('GridBody',{staticClass:"card-body"},[_c('b-skeleton',{attrs:{"as":"div","width":"40%","height":"1rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"2rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"80%","height":"0.75rem","animated":"","count":1}}),_vm._v(" "),_c('p',{staticClass:"subtitle is-size-6 is-text has-text-weight-medium mt-2 is-subtle summary-box"},[_c('b-skeleton',{attrs:{"as":"div","width":"100%","height":"1rem","animated":"","count":1}}),_vm._v(" "),_c('b-skeleton',{attrs:{"as":"div","width":"70%","height":"1rem","animated":"","count":1}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }